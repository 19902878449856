import * as React from "react"
import { ProrationAmount, SubscriptionStatus } from "@modules/graphql/types"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import {
  Card,
  CardSection,
  CardHeader,
  Text,
  Spacer,
  useShowSuccessToast,
} from "gatsby-interface"
import { SettingsCardEditButton } from "@modules/site/settings"
import { planInformation as planText } from "@modules/locales/default.js"
import { OrgSettingsSection } from "@modules/organization/Settings/constants.js"
import { OrganizationBaseDetailsDocument } from "@modules/organization/queries.generated"
import {
  Actions,
  Header,
  Addons,
  Addon,
  Confirmation,
  Preview,
} from "./PlanAddons.parts"
import { getMetricValues, formatBandwidthAmount } from "./PlanAddons.utils"
import { useOrganizationBaseDetailsFragment } from "@modules/organization/fragments.generated"
import {
  useGenerateProrationAmountMutation,
  usePurchaseAdditionalFeaturesMutation,
} from "@modules/billing/queries.generated"
import { useMembersUsage } from "@modules/organization/shared/hooks/useMembersUsage"
import { useFlags } from "@modules/featureFlags"

export type PlanAddonsProps = {
  organizationId: string
}

export function PlanAddons({ organizationId }: PlanAddonsProps) {
  const { flags } = useFlags()
  const BANDWIDTH_MULTIPLIER = flags.pricingFall2022 ? 104858 : 512000 // 104858MB = 102.4GB (binary) per add on, value in Stripe. Ensuring at *10 we reach 1TB
  const BANDWIDTH_INCREMENT_MULTIPLIER = flags.pricingFall2022 ? 100 : 500
  const [setError, errorAlert] = useTriggerErrorAlert()
  const showSuccessToast = useShowSuccessToast()
  const [generateProrationAmount] = useGenerateProrationAmountMutation()
  const [purchaseAdditionalFeatures] = usePurchaseAdditionalFeaturesMutation()

  const { data } = useOrganizationBaseDetailsFragment(organizationId)
  const hasPermissionToEdit = data?.permissions?.billing?.edit

  const membersUsage = useMembersUsage(organizationId)

  const [
    prorationResult,
    setProrationResult,
  ] = React.useState<ProrationAmount | null>()

  const [
    membersDowngradeIsBlocked,
    setMembersDowngradeIsBlocked,
  ] = React.useState(false)

  const initialUsersQuantity = React.useRef<number | null>()
  const [usersQuantity, setUsersQuantity] = React.useState<
    number | null | undefined
  >(null)

  const initialConcurrencyQuantity = React.useRef<number | null>()
  const [concurrencyQuantity, setConcurrencyQuantity] = React.useState<
    number | null | undefined
  >(null)

  const initialBandwidthQuantity = React.useRef<number | null>()
  const [bandwidthQuantity, setBandwidthQuantity] = React.useState<
    number | null | undefined
  >(null)

  const [isInEditMode, setIsInEditMode] = React.useState(false)
  const [saveIsEnabled, setSaveIsEnabled] = React.useState(false)
  const [confirmationIsOpen, setConfirmationIsOpen] = React.useState(false)
  const [isChecking, setIsChecking] = React.useState(false)
  const [isSaving, setIsSaving] = React.useState(false)

  React.useEffect(() => {
    const users = data?.billing?.plan?.additionalFeatures?.users?.quantity
    const concurrency =
      data?.billing?.plan?.additionalFeatures?.concurrency?.quantity
    const bandwidth =
      data?.billing?.plan?.additionalFeatures?.bandwidth?.quantity

    setUsersQuantity(users)
    // saves initial value to check if a user played with the form
    if (initialUsersQuantity.current !== users) {
      initialUsersQuantity.current = users
    }

    setConcurrencyQuantity(concurrency)
    // as above
    if (initialConcurrencyQuantity.current !== concurrency) {
      initialConcurrencyQuantity.current = concurrency
    }

    setBandwidthQuantity(bandwidth)
    // same here
    if (initialBandwidthQuantity.current !== bandwidth) {
      initialBandwidthQuantity.current = bandwidth
    }
  }, [data])

  React.useEffect(() => {
    let editedFeatures = getEditedFeatures()
    const newUsersQuantity = editedFeatures.find(item => item.name === `users`)
      ?.quantity

    if (
      typeof newUsersQuantity === `number` &&
      membersUsage?.used &&
      membersUsage?.base &&
      membersUsage?.used > membersUsage?.base + newUsersQuantity
    ) {
      setMembersDowngradeIsBlocked(true)
      editedFeatures = []
    } else {
      setMembersDowngradeIsBlocked(false)
    }

    if (
      // checks if any of addons quantity changed
      editedFeatures.length > 0
    ) {
      // cleans pre sell data
      setProrationResult(null)
      // shows checking action
      setIsChecking(true)
      // disable Save button (if it's enabled)
      setSaveIsEnabled(false)

      // pre sell
      generateProrationAmount({
        variables: {
          additionalFeatures: editedFeatures,
          workspaceId: organizationId,
        },
      })
        .then(({ data }) => {
          setProrationResult(data?.generateProrationAmount)
          // enables the Save button
          setSaveIsEnabled(true)
          setIsChecking(false)
        })
        .catch(err => {
          setError(err)
        })
    } else {
      // otherwise the button is disabled, see Actions component
      setSaveIsEnabled(false)
      // and clean proration message
      setProrationResult(null)
    }
  }, [usersQuantity, bandwidthQuantity, concurrencyQuantity])

  const isTrialing = data?.billing?.status === SubscriptionStatus.Trialing
  const baseFeatures = data?.billing?.plan?.baseFeatures
  const additionalFeatures = data?.billing?.plan?.additionalFeatures

  const handleUsersChange = (val: number) => {
    setUsersQuantity(val)
  }

  const handleConcurrencyChange = (val: number) => {
    setConcurrencyQuantity(val)
  }

  const handleBandwidthChange = (val: number) => {
    setBandwidthQuantity(val)
  }

  const handleSave = () => {
    setConfirmationIsOpen(true)
  }

  const handleCancel = () => {
    setIsInEditMode(false)
    setProrationResult(null)
    setUsersQuantity(initialUsersQuantity.current)
    setConcurrencyQuantity(initialConcurrencyQuantity.current)
    setBandwidthQuantity(initialBandwidthQuantity.current)
  }

  const handleConfirm = () => {
    setSaveIsEnabled(false)
    setConfirmationIsOpen(false)
    setIsSaving(true)

    purchaseAdditionalFeatures({
      variables: {
        additionalFeatures: getEditedFeatures(),
        workspaceId: organizationId,
        prorationDate: prorationResult?.prorationDate,
      },
      refetchQueries: [
        {
          query: OrganizationBaseDetailsDocument,
          variables: { id: organizationId },
        },
      ],
    })
      .then(() => {
        setIsSaving(false)
        setSaveIsEnabled(true)
        setIsInEditMode(false)
        setProrationResult(null)

        showSuccessToast(`Add-on applied`)
      })
      .catch(err => {
        setIsSaving(false)
        setSaveIsEnabled(true)
        setProrationResult(null)

        setError(err)
      })
  }

  const getEditedFeatures = () => {
    const features = []

    if (
      typeof usersQuantity === `number` &&
      usersQuantity !== initialUsersQuantity.current
    ) {
      features.push({ name: `users`, quantity: usersQuantity })
    }
    if (
      typeof concurrencyQuantity === `number` &&
      concurrencyQuantity !== initialConcurrencyQuantity.current
    ) {
      features.push({
        name: `concurrency`,
        quantity: concurrencyQuantity,
      })
    }
    if (
      typeof bandwidthQuantity === `number` &&
      bandwidthQuantity !== initialBandwidthQuantity.current
    ) {
      features.push({
        name: `bandwidth`,
        quantity: bandwidthQuantity / BANDWIDTH_MULTIPLIER,
      })
    }

    return features
  }

  const baseAddonsData: Pick<
    Addon,
    "name" | "label" | "quantity" | "initialQuantity" | "onChange"
  >[] = [
    {
      name: `users`,
      label: `Members`,
      quantity: usersQuantity,
      initialQuantity: initialUsersQuantity,
      onChange: handleUsersChange,
    },
    {
      name: `concurrency`,
      label: `Concurrent builds`,
      quantity: concurrencyQuantity,
      initialQuantity: initialConcurrencyQuantity,
      onChange: handleConcurrencyChange,
    },
    {
      name: `bandwidth`,
      label: flags.pricingFall2022 ? `Bandwidth` : `Bandwidth & requests`,
      quantity: bandwidthQuantity,
      initialQuantity: initialBandwidthQuantity,
      onChange: handleBandwidthChange,
    },
  ]

  const fullAddonsData: Addon[] = baseAddonsData.map(item => {
    const values = getMetricValues({
      name: item.name,
      baseFeatures,
      additionalFeatures,
      quantity: item.quantity,
    })

    return {
      ...item,
      limit: values.limit,
      limitAdjusted: values.limitAdjusted,
      interval: data?.billing?.plan?.interval,
      unitPrice: values.unitPrice,
    }
  })

  const extendedAddonsData = fullAddonsData.map((item: Addon) => {
    if (!item.limit) {
      return item
    }

    // sets customize labels & values for 'bandwidth'
    if (item.name === `bandwidth`) {
      item.options = Array.from(
        {
          length: Math.round(item.limit / BANDWIDTH_MULTIPLIER + 1),
        },
        (_, i) => ({
          label:
            i === 0
              ? `0`
              : `${
                  flags.pricingFall2022
                    ? `${formatBandwidthAmount(
                        i * BANDWIDTH_INCREMENT_MULTIPLIER
                      )}`
                    : `${i * BANDWIDTH_INCREMENT_MULTIPLIER} GB / ${i * 5}M`
                } `,
          value: `${i * BANDWIDTH_MULTIPLIER}`,
        })
      )

      if (item.limitAdjusted) {
        let bandwidthDisplayString

        if (flags.pricingFall2022) {
          bandwidthDisplayString = `${formatBandwidthAmount(
            Math.round(
              (item.limitAdjusted / BANDWIDTH_MULTIPLIER) *
                BANDWIDTH_INCREMENT_MULTIPLIER
            )
          )} additional`
        } else {
          bandwidthDisplayString = `${(item.limitAdjusted /
            BANDWIDTH_MULTIPLIER) *
            BANDWIDTH_INCREMENT_MULTIPLIER} GB`
        }

        // if we're not in the fall pricing 2022, add text around requests
        if (!flags.pricingFall2022) {
          bandwidthDisplayString += ` bandwidth / ${(item.limitAdjusted /
            BANDWIDTH_MULTIPLIER) *
            5}M requests`
        }
        item.customAdjustedLimit = bandwidthDisplayString
      }

      if (item.quantity && item.quantity > 0) {
        let customQuantityDisplayString = `${formatBandwidthAmount(
          (item.quantity / BANDWIDTH_MULTIPLIER) *
            BANDWIDTH_INCREMENT_MULTIPLIER
        )}`
        // if we're not in the fall pricing 2022, add text value about requests
        if (!flags.pricingFall2022) {
          customQuantityDisplayString += ` / ${(item.quantity /
            BANDWIDTH_MULTIPLIER) *
            5}M`
        }
        item.customQuantity = customQuantityDisplayString

        item.htmlInfo = flags.pricingFall2022 ? (
          <React.Fragment>
            <strong>
              {formatBandwidthAmount(
                (item.quantity / BANDWIDTH_MULTIPLIER) *
                  BANDWIDTH_INCREMENT_MULTIPLIER
              )}
            </strong>{" "}
            bandwidth
          </React.Fragment>
        ) : (
          <React.Fragment>
            <strong>
              {formatBandwidthAmount(
                (item.quantity / BANDWIDTH_MULTIPLIER) *
                  BANDWIDTH_INCREMENT_MULTIPLIER
              )}
            </strong>{" "}
            bandwidth /{" "}
            <strong>{(item.quantity / BANDWIDTH_MULTIPLIER) * 50}M</strong>{" "}
            requests
          </React.Fragment>
        )
      }
    } else {
      item.options = Array.from(
        {
          length: item.limit + 1,
        },
        (_, i) => ({
          label: `${i}`,
          value: `${i}`,
          disabled: true,
        })
      )

      if (item.quantity && item.quantity > 0) {
        item.htmlInfo = (
          <React.Fragment>
            <strong>{item.quantity}</strong> {item.label.toLowerCase()}
          </React.Fragment>
        )
      }
    }

    return item
  })

  const addons = extendedAddonsData.filter(
    item =>
      typeof item.limit === `number` &&
      item.limit > 0 &&
      typeof item.quantity === `number` &&
      typeof item.unitPrice === `string`
  )

  // checks if at least one add on is on sale
  const onSale = addons.some(
    item => typeof item.limit === `number` && typeof item.quantity === `number`
  )

  if (isTrialing) {
    return (
      <Card id={OrgSettingsSection.Addons} data-cy="plan-add-ons">
        <CardHeader title={planText.headers.addOns} />
        <CardSection applyPaddingTop={false}>
          <Text size="S" noMarginBottom={true} noMarginTop={true}>
            Add-ons are not available on the <b>trial</b> period.
          </Text>
        </CardSection>
      </Card>
    )
  }

  return (
    <React.Fragment>
      <Card id={OrgSettingsSection.Addons} data-cy="plan-add-ons">
        <CardHeader title={planText.headers.addOns}>
          {onSale && !isInEditMode && hasPermissionToEdit && (
            <SettingsCardEditButton
              onClick={() => setIsInEditMode(true)}
              variant={"GHOST"}
              label={planText.actions.editAddOns}
            />
          )}
        </CardHeader>
        {hasPermissionToEdit ? (
          <CardSection applyPaddingTop={false}>
            {onSale ? (
              <React.Fragment>
                <Text size="S">
                  Add-ons are pro-rated during the current billing period.
                </Text>

                <div>
                  <Header />
                  <Addons data={addons} isInEditMode={isInEditMode} />

                  {errorAlert && (
                    <React.Fragment>
                      <Spacer size={8} />
                      {errorAlert}
                    </React.Fragment>
                  )}

                  {isInEditMode && (
                    <Actions
                      onSave={handleSave}
                      onCancel={handleCancel}
                      saveIsEnabled={saveIsEnabled}
                      prorationResult={prorationResult}
                      isChecking={isChecking}
                      isSaving={isSaving}
                      membersDowngradeIsBlocked={membersDowngradeIsBlocked}
                      organizationId={organizationId}
                    />
                  )}
                </div>
              </React.Fragment>
            ) : (
              <Text size="S" noMarginBottom={true} noMarginTop={true}>
                No available add-ons for your plan.
              </Text>
            )}
          </CardSection>
        ) : (
          <CardSection applyPaddingTop={false}>
            <Preview data={addons} />
          </CardSection>
        )}
      </Card>
      <Confirmation
        onDismiss={() => setConfirmationIsOpen(false)}
        isOpen={confirmationIsOpen}
        getFeatures={getEditedFeatures}
        onConfirm={handleConfirm}
        proration={prorationResult}
      />
    </React.Fragment>
  )
}
