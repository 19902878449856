import format from "date-fns/format"
import sub from "date-fns/sub"
import add from "date-fns/add"
import { Theme } from "gatsby-interface"
import { PlatformLimitSummariesQuery } from "@modules/organization/queries.generated"
import { SubscriptionStatus } from "@modules/graphql/types"

export type GetMetricsParams = {
  data?: PlatformLimitSummariesQuery
  isFreePlan?: boolean
}

export function getRangeDates({
  trialStart,
  trialEnd,
  next,
  status,
}: {
  trialStart: string
  trialEnd: string
  next: string
  status?: SubscriptionStatus | null
}) {
  // for trial period
  if (trialStart && trialEnd && status === SubscriptionStatus.Trialing) {
    return [new Date(trialStart), new Date(trialEnd)]
  }

  // for paid plan
  if (next) {
    const now = new Date()
    const nextDay = new Date(next).getDate()
    const nowDay = now.getDate()
    const shift = nextDay - nowDay

    const end =
      shift > 0
        ? new Date(now.getFullYear(), now.getMonth(), nextDay - 1)
        : new Date(now.getFullYear(), now.getMonth() + 1, nextDay - 1)

    const start = add(sub(end, { months: 1 }), { days: 1 })

    return [start, end]
  }

  // for free plan
  const now = new Date()
  const first = new Date(now.getFullYear(), now.getMonth(), 1)
  const last = new Date(now.getFullYear(), now.getMonth() + 1, 0)

  return [first, last]
}

export function getDatesRangeMsg(start: Date, end: Date) {
  if (!start || !end) {
    return null
  }

  return `${format(start, "MMM d, yyyy")} - ${format(end, "MMM d, yyyy")}`
}

export function getResetDateMsg(end: Date) {
  if (!end) {
    return null
  }

  const resetDate = add(new Date(end), { days: 1 })

  return `* Usage amounts reset on ${format(resetDate, "MMM d, yyyy")}`
}

export function getProgressColor(theme: Theme, progression: number) {
  if (progression >= 100) {
    return theme.colors.orange[60]
  }

  return theme.colors.purple[60]
}
