import * as React from "react"
import { useWorkspaceInvitationsQuery } from "../queries.generated"
import { useWorkspaceMembers } from "../hooks/useWorkspaceMembers"
import { SubscriptionStatus } from "@modules/graphql/types"
import { MembersList } from "./MembersList"
import { MembersActions } from "./MembersActions"
import { MembersEmpty } from "./MembersEmpty"
import { MembersPagination } from "./MembersPagination"
import Loading from "@modules/ui/components/Loading"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import { orgMembers as text } from "@modules/locales/default.js"
import { Notification, Spacer } from "gatsby-interface"
import { MdInfo } from "react-icons/md"
import { getPathToOrgPricing } from "@modules/organization/shared/utils"
import { useMembersUsage } from "@modules/organization/shared/hooks/useMembersUsage"

export type WorkspaceMembersProps = {
  organizationId: string
  permissionToInvite: boolean
  permissionToRead: boolean
  permissionToDelete: boolean
  billingStatus: SubscriptionStatus
}

export function Members({
  billingStatus,
  organizationId,
  permissionToInvite,
  permissionToRead,
  permissionToDelete,
}: WorkspaceMembersProps) {
  const [setError, errorAlert] = useTriggerErrorAlert()
  const isFreePlan = billingStatus === SubscriptionStatus.Free
  const skipQuery = !permissionToRead
  const linkToPlanUpgrade = getPathToOrgPricing(organizationId)

  const [membersData, { loadingMore, loading: membersLoading, loadMore }] =
    useWorkspaceMembers(organizationId, setError, skipQuery)

  const { loading: invitationsLoading, data: invitationsData } =
    useWorkspaceInvitationsQuery({
      variables: {
        workspaceId: organizationId,
      },
      skip: skipQuery,
      onError: e => setError(e),
    })

  const usage = useMembersUsage(organizationId)

  const loading = membersLoading || invitationsLoading
  const invited = invitationsData?.workspaceInvitations?.members ?? []
  const active = membersData ?? []
  const members = [...invited, ...active]

  if (!permissionToRead) {
    return <MembersEmpty organizationId={organizationId} />
  }

  if (loading)
    return (
      <Loading
        delay={1000}
        message={text.messages.loadingOrgMembers}
        bufferSize="padded"
      />
    )

  return (
    <React.Fragment>
      {isFreePlan && (
        <React.Fragment>
          <Notification
            content="Free plans are limited to 1 member. To add another, upgrade to Professional, Agency or Enterprise plan."
            isOpened
            tone="BRAND"
            variant="PRIMARY"
            linkText="Upgrade plan"
            Icon={MdInfo}
            linkUrl={linkToPlanUpgrade}
          />
          <Spacer size={6} />
        </React.Fragment>
      )}

      {permissionToInvite && !isFreePlan && (
        <MembersActions organizationId={organizationId} usage={usage} />
      )}

      {errorAlert}

      {members.length > 0 && (
        <MembersList
          members={members}
          organizationId={organizationId}
          permissionToInvite={permissionToInvite}
          permissionToRead={permissionToRead}
          permissionToDelete={permissionToDelete}
          recalculateUsage={usage.recalculate}
        />
      )}

      {!loading && (
        <MembersPagination loadingMore={loadingMore} loadMore={loadMore} />
      )}
    </React.Fragment>
  )
}
