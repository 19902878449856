import * as React from "react"
import { sitesList as sitesText } from "@modules/locales/default.js"
import {
  Spacer,
  ThemeCss,
  Link,
  EmptyState,
  Heading,
  Card,
  VisuallyHidden,
} from "gatsby-interface"
import { MdOpenInNew } from "react-icons/md"
import {
  SourceControlRepository,
  SourceControlProvider,
  Site,
} from "@modules/graphql/types"
import { useFlaggedDictionary, interpolateMessage } from "@modules/locales"
import {
  SourceControlProviderIcon,
  sourceControlProviderLabels,
} from "@modules/sourceControlProvider"
import { EmptyStateGraphic } from "@modules/ui/components/EmptyStateGraphic"
import Loading from "@modules/ui/components/Loading"
import { Waypoint } from "react-waypoint"
import { SiteAbstract } from "./SiteAbstract"

type OrganizationRepository = Pick<
  SourceControlRepository,
  "id" | "name" | "nameWithOwner" | "url" | "provider"
> & {
  sites?: Pick<
    Site,
    | "id"
    | "name"
    | "publicName"
    | "branch"
    | "updatedAt"
    | "previewUrl"
    | "previewStatus"
    | "previewProtection"
    | "repositoryId"
    | "buildsEnabled"
    | "incrementalPreviewsEnabled"
    | "dataBuildsEnabled"
    | "previewBuildsEnabled"
    | "parentOrganizationId"
  >[]
}

export type SitesGroupedByRepositoryProps = {
  repositories: OrganizationRepository[]
  organizationId: string
  searchTerm?: string
  state?: `SEARCHING` | `DEFAULT`
  loadMore?: () => void
  loadingMore?: boolean
  searchText?: string
  debouncedSearchText?: string
}

export function SitesGroupedByRepository({
  repositories,
  organizationId,
  state = `DEFAULT`,
  loadMore,
  loadingMore,
  searchText = ``,
  debouncedSearchText,
}: SitesGroupedByRepositoryProps) {
  const flaggedText = useFlaggedDictionary(sitesText, `spaces`)
  const emptyStateCondition = repositories?.length === 0 && searchText

  if (emptyStateCondition) {
    return state === `SEARCHING` ? (
      <React.Fragment>
        <Spacer size={5} />
        <EmptyState
          heading={flaggedText.headers.noMatchingSites}
          text={interpolateMessage<"searchTerm">(
            flaggedText.messages.noMatchingSites,
            { searchTerm: searchText }
          )}
          headingAs="h2"
          graphic={<EmptyStateGraphic />}
          variant="BORDERED"
        />
      </React.Fragment>
    ) : null
  }

  return (
    <div css={rootCss}>
      {repositories?.map(repo => {
        const provider = repo.provider || SourceControlProvider.Github

        return (
          <Card as="section" key={repo.id}>
            <header css={repoCss}>
              <Heading as="h2" css={repoNameCss}>
                <VisuallyHidden>Repository</VisuallyHidden> {repo.name}
              </Heading>
              <Link
                href={repo.url}
                aria-label={interpolateMessage<"provider">(
                  flaggedText.labels.repositoryLinkAria,
                  { provider: sourceControlProviderLabels[provider] }
                )}
                variant="SIMPLE"
                css={repoLinkCss}
              >
                <SourceControlProviderIcon
                  sourceControlProvider={provider}
                  size="xxsmall"
                />
                <Spacer size={2} direction="horizontal" />
                {repo.nameWithOwner}
                <Spacer size={2} direction="horizontal" />
                <MdOpenInNew />
              </Link>
            </header>

            <VisuallyHidden>
              <Heading as="h3">Sites:</Heading>
            </VisuallyHidden>

            {repo.sites?.length && (
              <ul css={sitesCss}>
                {repo.sites.map(site => (
                  <li key={site.id}>
                    <SiteAbstract
                      {...site}
                      name={site.publicName || site.name}
                      organizationId={organizationId}
                    />
                  </li>
                ))}
              </ul>
            )}
          </Card>
        )
      })}

      {(debouncedSearchText !== searchText || loadingMore) && (
        <div css={{ textAlign: "center" }}>
          <Spacer size={3} />
          <Loading variant="baby" message={`Loading sites...`} />
        </div>
      )}
      <Waypoint onEnter={loadMore} />
    </div>
  )
}

/* styles  */

const rootCss: ThemeCss = theme => ({
  display: `grid`,
  gap: theme.space[8],
  marginTop: theme.space[9],
  marginBottom: theme.space[9],
})

const repoCss: ThemeCss = theme => ({
  display: `flex`,
  borderBottom: `1px solid ${theme.colors.standardLine}`,
  padding: `${theme.space[6]} ${theme.space[7]}`,
  flexWrap: `wrap`,
  alignItems: `center`,
})

const repoNameCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[2],
  marginRight: theme.space[5],
})

const repoLinkCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,
  color: theme.colors.grey[50],
  fontSize: theme.fontSizes[0],
  fontFamily: theme.fonts.monospace,
  margin: `${theme.space[2]} 0`,

  svg: {
    color: `inherit`,
  },
})

const sitesCss: ThemeCss = theme => ({
  listStyle: `none`,
  margin: 0,
  display: `grid`,
  gap: theme.space[6],
  padding: `${theme.space[7]} ${theme.space[8]} ${theme.space[8]}`,

  li: {
    margin: 0,
  },

  "li:not(:last-of-type)": {
    borderBottom: `1px solid ${theme.colors.standardLine}`,
    paddingBottom: theme.space[6],
  },
})
