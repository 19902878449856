import * as React from "react"
import {
  useWorkspaceMembersQuery,
  useWorkspaceMembersSimplifiedQuery,
} from "../queries.generated"
import * as merge from "deepmerge"
import { NetworkStatus } from "apollo-client"
import { useFlags } from "@modules/featureFlags"

export const LIMIT = 10
export const SIMPLIFIED_LIMIT =
  Number(process.env.GATSBY_WORKSPACE_MEMBERS_LIMIT) || 100

export const useWorkspaceMembers = (
  workspaceId: string,
  setError: ({ message }: { message: string }) => void,
  skip = false
) => {
  const [noLoadMoreResult, setNoLoadMoreResult] = React.useState(false)
  const { flags, ready } = useFlags()

  const {
    data: extendedData,
    loading: extendedLoading,
    error: extendedError,
    fetchMore: extendedFetchMore,
    networkStatus: extendedNetworkStatus,
  } = useWorkspaceMembersQuery({
    variables: {
      workspaceId,
      limit: LIMIT,
      offset: 0,
    },
    fetchPolicy: `cache-and-network`,
    onError: e => setError(e),
    skip: !ready || flags.simplifiedMembers || skip,
  })

  const {
    data: simplifiedData,
    loading: simplifiedLoading,
    error: simplifiedError,
    fetchMore: simplifiedFetchMore,
    networkStatus: simplifiedNetworkStatus,
  } = useWorkspaceMembersSimplifiedQuery({
    variables: {
      workspaceId,
      limit: SIMPLIFIED_LIMIT,
      offset: 0,
    },
    fetchPolicy: `cache-and-network`,
    onError: e => setError(e),
    skip: !ready || !flags.simplifiedMembers || skip,
  })

  const extendedLoadMore = () => {
    const allItems = extendedData?.workspaceMembers?.count ?? 0
    const loadedItems =
      (extendedData?.workspaceMembers?.members &&
        extendedData.workspaceMembers.members.length) ??
      0
    const isMore = allItems > loadedItems
    const isFetching = extendedNetworkStatus === NetworkStatus.fetchMore

    if (!isMore || isFetching) {
      return
    }

    extendedFetchMore({
      variables: {
        workspaceId,
        limit: LIMIT,
        offset: loadedItems,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return fetchMoreResult
          ? merge(previousResult, fetchMoreResult)
          : previousResult
      },
    })
  }

  const simplifiedLoadMore = () => {
    const allItems = simplifiedData?.workspaceMembersSimplified?.count ?? 0
    const loadedItems =
      (simplifiedData?.workspaceMembersSimplified?.members &&
        simplifiedData.workspaceMembersSimplified.members.length) ??
      0
    const isMore = allItems > loadedItems && loadedItems >= SIMPLIFIED_LIMIT
    const isFetching = simplifiedNetworkStatus === NetworkStatus.fetchMore

    if (noLoadMoreResult || !isMore || isFetching) {
      return
    }

    simplifiedFetchMore({
      variables: {
        workspaceId,
        limit: SIMPLIFIED_LIMIT,
        offset: loadedItems,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        // sometimes backend provides unsync numbers for `count` and `members.length`
        // what causes infinite loop of triggering 'loadMore', noLoadMoreResult flag breaks the loop
        if (
          fetchMoreResult?.workspaceMembersSimplified?.members?.length === 0
        ) {
          setNoLoadMoreResult(true)
        }

        return fetchMoreResult
          ? merge(previousResult, fetchMoreResult)
          : previousResult
      },
    })
  }

  return flags.simplifiedMembers
    ? ([
        simplifiedData?.workspaceMembersSimplified?.members,
        {
          loading: simplifiedLoading && !simplifiedData,
          loadingMore: Boolean(simplifiedLoading && simplifiedData),
          error: simplifiedError,
          loadMore: simplifiedLoadMore,
        },
      ] as const)
    : ([
        extendedData?.workspaceMembers?.members,
        {
          loading: extendedLoading && !extendedData,
          loadingMore: Boolean(extendedLoading && extendedData),
          error: extendedError,
          loadMore: extendedLoadMore,
        },
      ] as const)
}
