import getSideNavOptions from "@modules/layout/utils/getSideNavOptions"
import { getPathToOrgSettings } from "../../shared/utils"
import {
  OrgSettingsSectionLabel,
  OrgSettingsSection,
  orgSettings,
} from "../constants"
import { SubscriptionStatus } from "@modules/graphql/types"
import { useFlags } from "@modules/featureFlags"

export function useOrgSettingsNavigation(
  organizationId,
  location,
  organizationDetails
) {
  const { flags } = useFlags()
  // Only show status section if user is free tier or trialling
  const billingStatus =
    organizationDetails &&
    organizationDetails.billing &&
    organizationDetails.billing.status
  const isFreeOrTrialing = [
    SubscriptionStatus.Free,
    SubscriptionStatus.Trialing,
  ].includes(billingStatus)
  const paymentIsDefined = Boolean(organizationDetails?.billing?.creditCard)

  const filterWith = id => {
    if (id === OrgSettingsSection.Status && !isFreeOrTrialing) {
      return false
    }

    return true
  }

  return getSideNavOptions({
    sections: orgSettings({
      hasOnPremGitConnection: flags.onPremVcsConnection,
      workspaceSettingsVcsHealthCard: flags.workspaceSettingsVcsHealthCard,
      paymentIsDefined,
    }),
    sectionLabels: OrgSettingsSectionLabel,
    getLink: sectionId =>
      `${getPathToOrgSettings(organizationId)}/${sectionId}`,
    location,
    filterWith,
  })
}
