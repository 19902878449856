import * as React from "react"
import {
  BuildRunnerType,
  PreviewStatus,
  BuildStatus,
  Build,
} from "@modules/graphql/types"
import { useSiteChangedSubscription } from "@modules/site/shared/queries.generated"
import { MdArrowForward } from "react-icons/md"
import { Badge, ThemeCss, LinkButton } from "gatsby-interface"
import { SiteBuildStatus, SiteName } from "./SiteAbstract.parts"
import SiteBranch from "@modules/site/shared/components/SiteBranch"
import { getPreviewStatus } from "@modules/build/shared/utils"
import {
  getPathToSiteDetails,
  getPathToBuildDetails,
} from "@modules/site/details/utils"
import { LighthouseScores } from "@modules/metrics/components/LighthouseScores"
import { sites as text } from "@modules/locales/default.js"
import { idRegex } from "@modules/toolkit/constants"
import { useGetSiteTransferInvitationQuery } from "@modules/site/queries.generated"

export type SiteAbstractProps = {
  id: string
  organizationId: string
  latestBuild?: Build | null
  latestPreview?: Build | null
  name: string
  repositoryId: string
  previewStatus?: PreviewStatus | null
  buildsEnabled?: boolean | null
  previewBuildsEnabled?: boolean | null
  incrementalPreviewsEnabled?: boolean | null
  branch: string
  internalLinksEnabled?: boolean
  displayLighthouse?: boolean
  Slot?: React.ComponentType<{ siteId: string }>
  parentOrganizationId?: string
}

export function SiteAbstract({
  id,
  organizationId,
  latestBuild,
  latestPreview,
  name: siteName,
  previewStatus,
  buildsEnabled,
  previewBuildsEnabled,
  incrementalPreviewsEnabled,
  branch,
  internalLinksEnabled = true,
  displayLighthouse = true,
  parentOrganizationId,
  Slot,
}: SiteAbstractProps) {
  useSiteChangedSubscription({ variables: { id } })

  const pathToDetails = getPathToSiteDetails(id, organizationId)
  const pathToLatestBuildDetails =
    latestBuild && getPathToBuildDetails(latestBuild.id, id, organizationId)

  const buildStatus = latestBuild?.buildStatus
  const buildStartedAt = latestBuild?.startedAt
  const buildCreatedAt = latestBuild?.createdAt
  const buildDuration = latestBuild?.duration
  const buildEndedAt = latestBuild?.endedAt
  const latestBuildId = latestBuild?.id
  const isSuccessBuild = buildStatus === BuildStatus.Success

  const dashboardUrl = !internalLinksEnabled
    ? process.env.GATSBY_DASHBOARD_URL
    : ``

  const viewErrorLink = `${dashboardUrl}${pathToLatestBuildDetails}#errors`

  const { data } = useGetSiteTransferInvitationQuery({
    variables: {
      id,
    },
  })

  const siteTransferPending =
    !parentOrganizationId &&
    data?.getSiteTransferInvitation?.id &&
    !data?.getSiteTransferInvitation?.accepted

  const siteTransferred = parentOrganizationId

  return (
    <article css={rootCss}>
      <header css={headerCss}>
        <SiteName
          siteName={siteName}
          internalLinksEnabled={internalLinksEnabled}
          pathToDetails={pathToDetails}
        />

        {branch && <SiteBranch>{branch}</SiteBranch>}

        {siteTransferPending && (
          <Badge tone="WARNING" textVariant="DEFAULT" css={siteTransferCss}>
            {text.labels.transferPending}
          </Badge>
        )}

        {siteTransferred && (
          <Badge tone="NEUTRAL" textVariant="DEFAULT" css={siteTransferCss}>
            {text.labels.siteTransferred}
          </Badge>
        )}
      </header>

      <div css={statusesCss}>
        {latestBuild && (
          <React.Fragment>
            {buildsEnabled && (
              <SiteBuildStatus
                buildStatus={buildStatus}
                runnerType={BuildRunnerType.Builds}
                buildStartedAt={buildStartedAt}
                buildCreatedAt={buildCreatedAt}
                buildEndedAt={buildEndedAt}
                buildDuration={buildDuration}
                data-testid="build-status"
                a11yId={`Site--${id}--BuildStatus`}
              />
            )}

            {buildStatus === BuildStatus.Error && (
              <LinkButton
                to={viewErrorLink}
                variant="SECONDARY"
                size="S"
                tone="DANGER"
                rightIcon={<MdArrowForward />}
                target="__blank"
              >
                {text.actions.viewErrors}
              </LinkButton>
            )}
          </React.Fragment>
        )}

        {latestPreview && previewBuildsEnabled && (
          <SiteBuildStatus
            buildStatus={
              (incrementalPreviewsEnabled && latestPreview?.buildStatus) ||
              getPreviewStatus(latestPreview?.buildStatus || ``, previewStatus)
            }
            runnerType={BuildRunnerType.Preview}
            data-testid="preview-status"
            a11yId={`Site--${idRegex}--PreviewStatus`}
          />
        )}

        {!!Slot && <Slot siteId={id} />}
      </div>

      {displayLighthouse && isSuccessBuild && (
        <div>
          <LighthouseScores
            siteId={id}
            organizationId={organizationId}
            buildId={latestBuildId}
            branch={branch}
          />
        </div>
      )}
    </article>
  )
}

/* styles */

const rootCss: ThemeCss = theme => ({
  display: `grid`,
  gap: theme.space[3],
})

const headerCss: ThemeCss = theme => ({
  display: `flex`,
  flexWrap: `wrap`,
  alignItems: `center`,

  "& > *": {
    margin: `${theme.space[2]} ${theme.space[5]} ${theme.space[2]} 0`,
  },
})

const statusesCss: ThemeCss = theme => ({
  display: `flex`,
  flexWrap: `wrap`,

  "& > *": {
    margin: `${theme.space[1]} ${theme.space[5]} ${theme.space[1]} 0`,
  },
})

const siteTransferCss: ThemeCss = theme => ({
  marginLeft: `auto`,
})
