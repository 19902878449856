import * as React from "react"
import { Text, Card, CardSection, CardHeader, ThemeCss } from "gatsby-interface"
import { OrgSettingsSection, OrgSettingsSectionLabel } from "../constants.js"
import {
  getRangeDates,
  getDatesRangeMsg,
  getResetDateMsg,
} from "./OrganizationLimitsUsage.utils"
import { Metrics } from "./OrganizationLimitsUsage.parts"
import { useWorkspaceFeaturesUsage } from "../../shared/hooks/useWorkspaceFeaturesUsage"

export type OrganizationLimitsUsageProps = {
  organizationId: string
}

export function OrganizationLimitsUsage({
  organizationId,
}: OrganizationLimitsUsageProps) {
  const usage = useWorkspaceFeaturesUsage(organizationId)

  const [start, end] = getRangeDates({
    trialStart: usage.trialStartDate,
    trialEnd: usage.trialEndDate,
    next: usage.nextBillingDate,
    status: usage.billingStatus,
  })

  const datesRangeMsg = getDatesRangeMsg(start, end)
  const resetDateMsg = getResetDateMsg(end)

  return (
    <Card id={OrgSettingsSection.Usage}>
      <CardHeader title={OrgSettingsSectionLabel[OrgSettingsSection.Usage]} />
      <CardSection applyPaddingTop={false} css={sectionCss}>
        {datesRangeMsg && (
          <Text size="S" noMarginBottom={true}>
            {datesRangeMsg}
          </Text>
        )}
        {usage.metrics && <Metrics data={usage.metrics} />}
        {resetDateMsg && (
          <Text size="S" noMarginBottom={true}>
            {resetDateMsg}
          </Text>
        )}
      </CardSection>
    </Card>
  )
}

/* styles */

const sectionCss: ThemeCss = theme => ({
  display: `grid`,
  rowGap: theme.space[5],
})
