import { keyframes } from "@emotion/core"

const entry = keyframes`
  to {
    opacity: 1;
  }
`

export const simpleEntryAnimationCss = (
  duration = 0,
  delay = 0,
  unit: `s` | `ms` = `ms`
) => ({
  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${entry} ${duration}${unit} ease forwards`,
    animationDelay: `${delay}${unit}`,
    opacity: 0,
  },
})
