import * as React from "react"
import { orgMembers as orgMembersText } from "@modules/locales/default.js"
import { InviteMemberPanelModal } from "@modules/organization/contributors/components/MemberActionModals"
import {
  Button,
  Modal,
  ModalCard,
  StyledModal,
  StyledModalHeader,
  StyledModalBody,
  StyledModalActions,
  ThemeCss,
} from "gatsby-interface"
import { MdAdd } from "react-icons/md"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { useFlags } from "@modules/featureFlags"
import { navigate } from "gatsby"
import {
  getPathToOrgPricing,
  getPathToOrgSettings,
} from "@modules/organization/shared/utils"
import { UsageData } from "@modules/organization/shared/hooks/useMembersUsage"

export type AddMemberActionProps = {
  organizationId: string
  className?: string
  numOfMembers?: number
  usersQuantity?: number | null
  usersMax?: number | null
  usage: UsageData
}

function AddMemberAction({
  organizationId,
  className,
  usage,
}: AddMemberActionProps) {
  const { flags } = useFlags()
  const { trackSegment, trackButtonClicked } = useTracker()
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = React.useState(false)
  const [isBlockModalOpen, setIsBlockModalOpen] = React.useState(false)

  const handleClick = () => {
    if (usage.isOccupied && !usage.isUnlimited) {
      setIsBlockModalOpen(true)
    } else {
      setIsAddMemberModalOpen(true)
    }
  }

  return (
    <React.Fragment>
      <Button
        size={`M`}
        onClick={() => {
          trackButtonClicked(`Activated "Add Members" button`, {
            uiSource: `Members tab`,
          })
          trackSegment({
            type: SegmentEventType.Track,
            event: `Activated "Add Members" button`,
            properties: {
              location: "Members tab",
            },
          })
          handleClick()
        }}
        rightIcon={<MdAdd />}
        className={className}
      >
        {orgMembersText.actions.inviteMembers}
      </Button>

      <InviteMemberPanelModal
        organizationId={organizationId}
        isOpen={isAddMemberModalOpen}
        closeModal={() => setIsAddMemberModalOpen(false)}
        usage={usage}
      />

      <Modal
        isOpen={isBlockModalOpen}
        onDismiss={() => setIsBlockModalOpen(false)}
      >
        <ModalCard>
          <StyledModal variant="ACTION">
            <StyledModalHeader
              onCloseButtonClick={() => setIsBlockModalOpen(false)}
            >
              You’ve reached your plan limit of{" "}
              <strong css={strongCss}>{usage.all} members</strong>.
            </StyledModalHeader>
            <StyledModalBody>
              {usage.isExhausted
                ? `To add new members, upgrade to an Enterprise plan.`
                : `To add new members to your plan, visit your settings page. Each new member will be billed separately from your plan.`}
              <StyledModalActions>
                <Button
                  variant="SECONDARY"
                  tone="NEUTRAL"
                  type="button"
                  onClick={() => {
                    setIsBlockModalOpen(false)
                  }}
                >
                  Back to workspace
                </Button>
                <Button
                  variant="PRIMARY"
                  type="submit"
                  onClick={() => {
                    setIsBlockModalOpen(false)
                    navigate(
                      usage.isExhausted
                        ? getPathToOrgPricing(organizationId)
                        : `${getPathToOrgSettings(
                            organizationId
                          )}/general#addons`
                    )
                  }}
                >
                  {usage.isExhausted ? `Upgrade plan` : `Visit settings`}
                </Button>
              </StyledModalActions>
            </StyledModalBody>
          </StyledModal>
        </ModalCard>
      </Modal>
    </React.Fragment>
  )
}

export default AddMemberAction

/* styles */

const strongCss: ThemeCss = theme => ({
  color: theme.colors.purple[60],
})
