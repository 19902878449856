import * as React from "react"
import { Contributor } from "@modules/graphql/types"
import { MemberCard } from "./MemberCard"
import { Member } from "./Member"
import { Theme } from "gatsby-interface"
import { useFlags } from "@modules/featureFlags"
import { Interpolation } from "@emotion/core"

export type MembersListProps = {
  members: Contributor[]
  organizationId: string
  permissionToInvite: boolean
  permissionToRead: boolean
  permissionToDelete: boolean
  recalculateUsage: () => void
}

export function MembersList({
  members,
  organizationId,
  permissionToInvite,
  permissionToRead,
  permissionToDelete,
  recalculateUsage,
}: MembersListProps) {
  const { flags } = useFlags()

  return (
    <ul css={theme => listCss(theme, flags.simplifiedMembers)}>
      {members.map(member => (
        <React.Fragment key={member.id}>
          {flags.simplifiedMembers ? (
            <Member
              key={member.id}
              member={member}
              organizationId={organizationId}
              hasPermissionToRead={permissionToRead}
              hasPermissionToDelete={permissionToDelete}
              recalculateUsage={recalculateUsage}
            />
          ) : (
            <MemberCard
              key={member.id}
              member={member}
              organizationId={organizationId}
              permissionToInvite={permissionToInvite}
              permissionToRead={permissionToRead}
              permissionToDelete={permissionToDelete}
              recalculateUsage={recalculateUsage}
            />
          )}
        </React.Fragment>
      ))}
    </ul>
  )
}
/* styles */

const listCss = (theme: Theme, flag: boolean): Interpolation => {
  return [
    {
      display: `flex`,
      flexDirection: `column`,
      margin: 0,
    },
    flag && {
      display: `grid`,
      gap: theme.space[4],
    },
  ]
}
