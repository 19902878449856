import * as React from "react"
import { MemberActionsDropdown } from "./MemberActionsDropdown"
import { InviteeActions } from "./InviteeActions"
import {
  ThemeCss,
  Heading,
  Button,
  DropdownMenu,
  DropdownMenuPopover,
  DropdownMenuButton,
  DropdownMenuItems,
  DropdownMenuLink,
  useDropdownMenuContext,
} from "gatsby-interface"
import {
  CloudResource,
  CloudResourceType,
  CloudRole,
  Contributor as ContributorType,
  ContributorRole,
} from "@modules/graphql/types"
import { FaChevronDown, FaChevronUp } from "react-icons/fa"
import { manageContributors as manageContributorsText } from "@modules/locales/default.js"
import { interpolateMessage } from "@modules/locales"
import { getPathToSiteDetails } from "@modules/site/details/utils"
import { Link } from "gatsby"

const memberManagementContainerCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,
  width: `100%`,
  justifyContent: `space-between`,
  [theme.mediaQueries.phablet]: {
    justifyContent: `flex-end`,
  },
})

export type MemberRoleProps = {
  role: ContributorRole
  readonly?: boolean
}

function MemberRole({ role }: MemberRoleProps) {
  return (
    <Heading
      css={theme => ({
        fontSize: theme.fontSizes[1],
        textTransform: `capitalize`,
      })}
      data-cy="member-role"
    >
      {role.toLowerCase()}
    </Heading>
  )
}

export type MemberAccessButtonProps = {
  children: React.ReactNode
}

function MemberAccessButton({ children }: MemberAccessButtonProps) {
  const { isExpanded } = useDropdownMenuContext()

  const buttonCss: ThemeCss = theme => ({
    color: theme.colors.grey[50],
    marginRight: `-.6rem`,
  })

  const chevronIconCss: ThemeCss = theme => ({
    fontSize: theme.fontSizes[1],
    color: theme.colors.purple[50],
    opacity: 0.5,
  })

  return (
    <Button
      ButtonComponent={DropdownMenuButton}
      size="M"
      variant="GHOST"
      rightIcon={
        isExpanded ? (
          <FaChevronDown css={chevronIconCss} />
        ) : (
          <FaChevronUp css={chevronIconCss} />
        )
      }
      css={buttonCss}
    >
      {children}
    </Button>
  )
}

const allSitesTextCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[50],
})

type MemberAccessProps = {
  organizationId: string
  memberResources: CloudResource[]
}

function MemberAccess({ organizationId, memberResources }: MemberAccessProps) {
  let memberOrganizationRole: CloudRole | null = null
  const memberSiteResources: CloudResource[] = []

  for (const resource of memberResources) {
    if (resource.resourceType === CloudResourceType.Organization) {
      memberOrganizationRole = resource.role
    }
    if (resource.resourceType === CloudResourceType.Site) {
      memberSiteResources.push(resource)
    }
  }

  const hasLimitedAccess =
    memberOrganizationRole === CloudRole.LimitedContributor ||
    memberOrganizationRole === CloudRole.LimitedReader

  if (!hasLimitedAccess) {
    return (
      <span css={allSitesTextCss}>
        {manageContributorsText.labels.memberSiteAccessAll}
      </span>
    )
  }

  return (
    <DropdownMenu>
      <MemberAccessButton>
        {interpolateMessage<"count">(
          manageContributorsText.labels.memberSiteAccessSome,
          { count: memberSiteResources.length }
        )}
      </MemberAccessButton>
      <DropdownMenuPopover>
        <DropdownMenuItems size="MAX_CONTENT">
          {memberSiteResources.map(siteResource => (
            <DropdownMenuLink
              as={Link}
              to={getPathToSiteDetails(siteResource.resourceId, organizationId)}
              key={siteResource.resourceId}
            >
              {siteResource.name}
            </DropdownMenuLink>
          ))}
        </DropdownMenuItems>
      </DropdownMenuPopover>
    </DropdownMenu>
  )
}

const memberDetailsCss: ThemeCss = _theme => ({
  display: `flex`,
  flexDirection: `column`,
  textAlign: `right`,
})

export type MemberDetailsProps = {
  children: React.ReactNode
  hasActions?: boolean
}

// In the case that actions are not shown, we keep visual consistency with the
// MemberDetails section by applying a margin-right of the same size
const MEMBER_ACTIONS_WIDTH = `3.4rem`

function MemberDetails({ children, hasActions = true }: MemberDetailsProps) {
  return (
    <div
      css={theme => [
        memberDetailsCss(theme),
        !hasActions && { marginRight: MEMBER_ACTIONS_WIDTH },
      ]}
    >
      {children}
    </div>
  )
}

const memberActionsCss: ThemeCss = _theme => ({
  display: `flex`,
  justifyContent: `flex-end`,
  width: MEMBER_ACTIONS_WIDTH,
})

export type MemberActionsProps = {
  children: React.ReactNode
}

function MemberActions({ children }: MemberActionsProps) {
  return <div css={memberActionsCss}>{children}</div>
}

export type MemberManagementProps = {
  member: ContributorType
  organizationId: string
  permissionToInvite: boolean
  permissionToRead: boolean
  permissionToDelete: boolean
  className?: string
  recalculateUsage?: () => void
}

function MemberManagement({
  member,
  organizationId,
  permissionToInvite,
  permissionToRead,
  permissionToDelete,
  className,
  recalculateUsage,
}: MemberManagementProps) {
  const { contributorRole } = member
  const hideRoleForInviteeActions =
    permissionToInvite && contributorRole === ContributorRole.Invited
  const showMemberActions =
    permissionToDelete &&
    [
      ContributorRole.Editor,
      ContributorRole.Viewer,
      ContributorRole.Admin,
    ].includes(contributorRole)

  const memberResources = member?.resources

  return (
    <div className={className} css={memberManagementContainerCss}>
      {permissionToRead && !hideRoleForInviteeActions && (
        <MemberDetails hasActions={showMemberActions}>
          <MemberRole
            role={contributorRole}
            readonly={!permissionToInvite || !permissionToDelete}
          />
          {memberResources && (
            /**
             * When "memberResources" is null, it means that the current user is a limited viewer/editor
             * and should not have access to the information about other members' site access
             */
            <MemberAccess
              organizationId={organizationId}
              memberResources={memberResources}
            />
          )}
        </MemberDetails>
      )}
      {showMemberActions && (
        <React.Fragment>
          <MemberActions>
            <MemberActionsDropdown
              member={member}
              resourceId={organizationId}
              recalculateUsage={recalculateUsage}
            />
          </MemberActions>
        </React.Fragment>
      )}

      {permissionToInvite && contributorRole === ContributorRole.Invited && (
        <InviteeActions
          member={member}
          resourceId={organizationId}
          organizationId={organizationId}
          recalculateUsage={recalculateUsage}
        />
      )}
    </div>
  )
}

export default MemberManagement
